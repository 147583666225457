//? Adios a las magic strings.

const FRONT_PATH = [
  "https://algorithmic-market.com",
  "https://www.algorithmic-market.com",
];
const BACK_PATH = "https://server.algorithmic-market.com";

const PROGRESS_VALUE = 11;
const PRIV_KEY =
  "sk_test_51NdfNbCPshfmc7ctMo1Y4L8E9Hkl6KMeF8KzjSqdPgRxHdQRfREoONTs37h7wvbrJk2jixgjSLvT7EbRRJctHKpO00wQnsY8yV";

// const FRONT_PATH = "http://localhost:3000";
// const BACK_PATH = "http://localhost:3001";

//* Key Nuestra: "sk_test_51NdfNbCPshfmc7ctMo1Y4L8E9Hkl6KMeF8KzjSqdPgRxHdQRfREoONTs37h7wvbrJk2jixgjSLvT7EbRRJctHKpO00wQnsY8yV"
//* Key Deivid: "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"

export { FRONT_PATH, BACK_PATH, PROGRESS_VALUE, PRIV_KEY };
